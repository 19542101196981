Vue.component("slider-component", {
    data() {
        return {
            flickityOptions: {
                prevNextButtons: false,
                pageDots: false,
                selectedAttraction: .05,
                friction: .75,
                imagesLoaded: true,
                setGallerySize: false,
                resize: true,
                contain: true,
                wrapAround: true,
                cellAlign: 'left',
                draggable: true,
            },
            flickity: null,
            slides: 0,
            activeSlide: 1,
            windowWidth: window.innerWidth
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.init()
        })

        window.addEventListener('resize', this.checkWindowWidth)
    },
    destroyed() {
        window.removeEventListener('resize', this.checkWindowWidth)
    },
    methods: {
        init() {
            this.flickity = new Flickity(this.$refs.flickity, this.flickityOptions)
            this.slides = this.flickity.slides.length
            this.flickity.on('change', (index) => {
                // Set correct index when slide is swiped
                this.activeSlide = index + 1
            })
        },
        next() {

            this.flickity.next()
        },
        previous() {
            this.flickity.previous()
        },
        checkWindowWidth() {
            if(this.windowWidth !== window.innerWidth) {
                this.windowWidth = window.innerWidth
                this.resize()
            }
        },
        resize() {
            this.flickity.destroy()

            this.init()
        }
    },
});
