Vue.component("loader", {
    template: `
        <div class="loader">
            <div class="loader__circle"></div>
            <span class="loader__text" v-if="hasSlotData">
                <slot></slot>
            </span>
        </div>
    `,
    computed: {
        hasSlotData: function () {
            return this.$slots.default;
        }
    }
});
