// const VueWithCompiler = require('vue/dist/vue.esm')

Vue.component("html-component", {


    props: {
        html: {
            type: String,
            default: "",
        }
    },
    data: function () {
        return {
            selected: undefined,
            templateRender: undefined,
        }
    },
    watch: {
        html() {
            this.updateRender()
        }
    },
    created() {
        this.updateRender()
    },
    methods: {
        updateRender() {
            console.log('update render')
            const compiled = VueWithCompiler.compile('<div>' + this.html + '</div>');
            this.templateRender = compiled.render;
            this.$options.staticRenderFns = [];
            for (const staticRenderFunction of compiled.staticRenderFns) {
                this.$options.staticRenderFns.push(staticRenderFunction);
            }
        },
        render(createElement) {
            return this.templateRender();
        },
    },
});
